'use client'

import { usePathname, useSearchParams } from 'next/navigation'
import postHog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { useEffect } from 'react'

import { env } from '@/env'
import { PHEventName } from '@/lib/posthog/events'

if (typeof window !== 'undefined') {
  postHog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
    // Enable debug mode in development
    loaded: (postHog) => {
      if (process.env.NODE_ENV === 'development') postHog.debug(false)
    },
    debug: false,
    person_profiles: 'always',
  })
}

type PHViewCaptureProps = {
  eventName?: PHEventName | string
  pageName?: string
  setProps?: Record<string, any>
  setOnceProps?: Record<string, any>
}

export function PHViewCapture({
  eventName = PHEventName.PAGE_VIEW,
  pageName,
  setProps,
  setOnceProps,
}: PHViewCaptureProps) {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    if (pathname && postHog) {
      let url = window.origin + pathname
      if (searchParams && searchParams.toString()) {
        url = url + `?${searchParams.toString()}`
      }
      postHog.capture(eventName.toString(), {
        $current_url: url,
        ...(pageName ? { pageName: pageName } : {}),
        ...(setProps ? { $set: setProps } : {}),
        ...(setOnceProps ? { $set_once: setOnceProps } : {}),
      })
    }
  }, [eventName, pageName, pathname, searchParams, setOnceProps, setProps])

  return null
}

export function PHProvider({ children }: { children: React.ReactNode }) {
  return <PostHogProvider client={postHog}>{children}</PostHogProvider>
}
