'use client'
import { PartialMessage } from '@bufbuild/protobuf'
import { Media } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/media_pb'
import { HeartIcon, LogoStandaloneIcon } from '@fingertip/icons'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'nextjs-toploader/app'
import { usePostHog } from 'posthog-js/react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { PHEventName } from '@/lib/posthog/events'

import { FloatingAction } from '../shared/floating-action'
import { ClaimLogo } from './claim-logo'

export type Props = {
  siteSlug: string
  siteId: string
  pageSlug: string
  pageId: string
  name?: string | null
  isPreview?: boolean
  logoMedia?: PartialMessage<Media>
}

export const PreviewButton = ({
  siteSlug,
  siteId,
  pageSlug,
  pageId,
  name,
  isPreview,
  logoMedia,
}: Props) => {
  const { t } = useTranslation()
  const router = useRouter()
  const searchParams = useSearchParams()
  const postHog = usePostHog()
  const hideClaimButton = searchParams?.get('hide-claim-button')
  const opengraph = searchParams?.get('opengraph')

  const handleClick = useCallback(() => {
    postHog.capture(PHEventName.PAGE_CLAIM_CLICK, {
      page_slug: pageSlug,
      page_id: pageId,
      site_slug: siteSlug,
      site_id: siteId,
    })

    router.push(`/sites/${siteSlug}/claiming`)
  }, [pageId, pageSlug, postHog, router, siteId, siteSlug])

  if (!isPreview) {
    return null
  }

  if (!!hideClaimButton || !!opengraph) {
    return null
  }

  return (
    <>
      <div className="pt-[200px]"></div>
      <FloatingAction hasBackground={false}>
        <div className="mx-auto flex w-full justify-center">
          <div className="backdrop-overlay flex w-full max-w-[480px] flex-col items-center justify-center rounded-3xl border-0.5 border-border bg-popover/50 p-4 shadow-sm">
            <div className="mb-1 flex items-center">
              <div className="-rotate-12">
                <ClaimLogo name={name} logoMedia={logoMedia} />
              </div>

              <HeartIcon className="mx-3 fill-red-500 text-red-500" />

              <div className="flex size-[38px] rotate-12 items-center justify-center rounded-[13px] bg-primary text-white shadow-xs">
                <LogoStandaloneIcon height={20} width={24} />
              </div>
            </div>

            <h3 className="h4 mb-3 text-center">{t('your_free_page_await')}</h3>

            <div className="w-full">
              <Button
                onClick={handleClick}
                className="pointer-events-auto w-full shadow-sm"
              >
                <div className="mr-2">
                  <LogoStandaloneIcon height={20} width={24} />
                </div>
                {t('claim_page_for_free')}
              </Button>
            </div>
          </div>
        </div>
      </FloatingAction>
    </>
  )
}
