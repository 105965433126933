'use client'
import React from 'react'

import { rootStore, RootStoreContext } from './root-store'

export const RootStoreProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <RootStoreContext.Provider value={rootStore}>
      {children}
    </RootStoreContext.Provider>
  )
}
