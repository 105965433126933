'use client'
import { disableQuery, useQuery } from '@connectrpc/connect-query'
import { getStoreCartPublic } from '@fingertip/creator-proto/gen/fingertip/creator/store_cart/v1/store_cart-StoreCartService_connectquery'
import { observer } from 'mobx-react-lite'
import Link from 'next/link'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useToken } from '@/lib/hooks/use-token'
import { RootStoreContext } from '@/lib/stores/root-store'

import { FloatingAction } from '../shared/floating-action'
import { Button } from '../ui/button'

type Props = {
  siteSlug: string
  domain?: string
}

export const ViewCartButton = observer(({ siteSlug, domain }: Props) => {
  const { t } = useTranslation()
  const { hasFetched, callOptions } = useToken()

  const {
    publicStore: { setShouldRefetchCart, shouldRefetchCart },
  } = useContext(RootStoreContext)

  const { data: cartData, refetch: cartRefetch } = useQuery(
    getStoreCartPublic,
    !hasFetched
      ? disableQuery
      : {
          siteSlug,
        },
    {
      callOptions,
      placeholderData: (prev) => prev,
    },
  )

  useEffect(() => {
    if (shouldRefetchCart) {
      cartRefetch()
      setShouldRefetchCart(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetchCart])

  const totalItems = (cartData?.cartItems || []).reduce(
    (sum, item) => sum + (item?.cartItem?.quantity || 1),
    0,
  )

  if (!totalItems) {
    return null
  }

  return (
    <>
      <div className="pt-[68px]"></div>
      <FloatingAction>
        <Button asChild className="w-full">
          <Link href={`${domain ? '/' : `/${siteSlug}/`}cart`} replace={false}>
            {t('view_cart')}
            <div className="ml-2.5 rounded-[6px] text-center flex items-center justify-center bg-white text-black min-w-5 h-5 font-semibold text-[11px]">
              {totalItems}
            </div>
          </Link>
        </Button>
      </FloatingAction>
    </>
  )
})
