'use client'
import { getCookie, setCookie } from 'cookies-next'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonDiv } from '@/components/ui/button'
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog'
import { SENSITIVE_CONTENT_ACCEPTED } from '@/lib/utils/constants'

export const SensitiveContentDialog = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const searchParams = useSearchParams()
  const opengraph = searchParams?.get('opengraph')

  useEffect(() => {
    const isAccepted: any = getCookie(SENSITIVE_CONTENT_ACCEPTED)
    setOpen(isAccepted !== 'true')
  }, [])

  const handleAcceptance = useCallback(() => {
    setCookie(SENSITIVE_CONTENT_ACCEPTED, 'true')
    setOpen(false)
  }, [])

  if (!!opengraph) {
    return null
  }

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <div className="mt-6 flex flex-col items-center justify-center">
            <DialogTitle className="h1 mb-1">
              {t('sensitive_content')}
            </DialogTitle>
            <p className="mb-6 text-lg">
              {t('you_must_be_of_a_legal_age_to_view_this_content')}
            </p>

            <Button
              variant="secondary"
              size="lg"
              className="w-full"
              onClick={handleAcceptance}
            >
              {t('im_over_18_-_enter')}
            </Button>

            <Link href="https://google.com" className="w-full">
              <ButtonDiv variant="ghost" size="lg" className="w-full">
                {t('im_under_18_-_exit')}
              </ButtonDiv>
            </Link>
          </div>
        </DialogContent>
      </Dialog>

      <meta name="RATING" content="RTA-5042-1996-1400-1577-RTA" />
    </>
  )
}
