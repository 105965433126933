'use client'

import { TransportProvider } from '@connectrpc/connect-query'
import { createConnectTransport } from '@connectrpc/connect-web'
import {
  QueryClient,
  QueryClientProvider as QueryClientProviderPrimitive,
} from '@tanstack/react-query'
import React from 'react'

import { env } from '@/env'

export const QueryClientProvider = ({ children }: React.PropsWithChildren) => {
  const [client] = React.useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          // With SSR, we usually want to set some default staleTime
          // above 0 to avoid refetching immediately on the client
          staleTime: 60 * 1000,
          gcTime: 5 * 60 * 1000, // 5 minutes
          refetchOnWindowFocus: true,
          refetchInterval: false, // set to a number in milliseconds for automatic refetching
          retry: (failureCount, error) => {
            if (failureCount >= 2) {
              return false
            }

            if (error.message === '[unavailable] HTTP 429') {
              return false
            }

            return true
          }, // Number of retries on failure
        },
        mutations: {
          retry: 0,
        },
      },
    }),
  )

  const transport = createConnectTransport({
    baseUrl: env.NEXT_PUBLIC_CREATOR_API,
  })

  return (
    <TransportProvider transport={transport}>
      <QueryClientProviderPrimitive client={client}>
        {children}
      </QueryClientProviderPrimitive>
    </TransportProvider>
  )
}
