'use client'
import { PartialMessage } from '@bufbuild/protobuf'
import { GetPagePublicResponse } from '@fingertip/creator-proto/gen/fingertip/creator/page/v1/page_pb'
import { useSearchParams } from 'next/navigation'
import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { RootStoreContext } from '@/lib/stores/root-store'

import { FloatingAction } from '../shared/floating-action'

type Props = {
  page?: PartialMessage<GetPagePublicResponse>
}

export const ContactButton = ({ page }: Props) => {
  const { t } = useTranslation()
  const block = (page?.blocks || []).filter(
    (block) => block.kind === 'CONTACT',
  )?.[0]
  const searchParams = useSearchParams()
  const utmSource = searchParams?.get('utm_source')
  const isSource = [
    'apple-wallet',
    'google-wallet',
    'contact',
    'qr-block',
  ].includes(String(utmSource))
  const shouldShow = isSource && !!block
  const {
    publicStore: { setDialogBlockId, setContactDialogStage },
  } = useContext(RootStoreContext)

  const handleSaveClick = useCallback(() => {
    setContactDialogStage('SAVE')
    setDialogBlockId(block?.id || null)
  }, [block?.id, setContactDialogStage, setDialogBlockId])

  const handleShareClick = useCallback(() => {
    setContactDialogStage('SEND')
    setDialogBlockId(block?.id || null)
  }, [block?.id, setContactDialogStage, setDialogBlockId])

  if (!shouldShow) {
    return null
  }

  return (
    <>
      <div className="pt-[68px]"></div>
      <FloatingAction>
        <div className="flex space-x-2">
          <Button
            className="w-full bg-background!"
            onClick={handleShareClick}
            variant="secondary"
          >
            {t('share_details')}
          </Button>

          <Button className="w-full" onClick={handleSaveClick}>
            {t('save_to_contacts')}
          </Button>
        </div>
      </FloatingAction>
    </>
  )
}
