'use client'
import { useMutation } from '@connectrpc/connect-query'
import { verifySitePassword } from '@fingertip/creator-proto/gen/fingertip/creator/site/v1/site-SiteService_connectquery'
import { zodResolver } from '@hookform/resolvers/zod'
import { getCookie, setCookie } from 'cookies-next'
import { useSearchParams } from 'next/navigation'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { z } from 'zod'

import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog'

import { PasswordField } from '../fields/password-field'
import { Button } from '../ui/button'

const SITE_PASSWORD_TOKEN_PREFIX = 'site_password_token_'
const TOKEN_EXPIRY_HOURS = 24

const schema = z.object({
  password: z.string().min(1, 'Password is required'),
})

type Schema = z.infer<typeof schema>

type Props = {
  siteSlug: string
}

export const SitePasswordProtectionDialog = ({ siteSlug }: Props) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const searchParams = useSearchParams()
  const opengraph = searchParams?.get('opengraph')

  const { control, handleSubmit } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      password: '',
    },
  })

  const mutation = useMutation(verifySitePassword, {
    onSuccess: (data) => {
      // Set cookie with 24-hour expiration
      const expiryDate = new Date()
      expiryDate.setHours(expiryDate.getHours() + TOKEN_EXPIRY_HOURS)

      setCookie(`${SITE_PASSWORD_TOKEN_PREFIX}${siteSlug}`, data?.token, {
        expires: expiryDate,
        // Optional additional security settings
        secure: true,
        sameSite: 'strict',
      })

      setOpen(false)
      toast.success(t('success'))
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  useEffect(() => {
    const hasToken = getCookie(`${SITE_PASSWORD_TOKEN_PREFIX}${siteSlug}`)
    setOpen(!hasToken)
  }, [siteSlug])

  const onSubmit = useCallback(
    async (data: Schema) => {
      await mutation.mutateAsync({
        siteSlug,
        password: data.password,
      })
    },
    [mutation, siteSlug],
  )

  if (!!opengraph) {
    return null
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        overlayClassName="bg-black!"
        onPointerDownOutside={(e) => e.preventDefault()}
        hideClose
      >
        <div className="mt-6 flex flex-col items-center justify-center">
          <DialogTitle className="h1 mb-1">
            {t('this_site_is_protect')}
          </DialogTitle>
          <p className="mb-6">{t('to_view_please_enter')}</p>

          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <PasswordField
              control={control}
              name="password"
              label={null}
              placeholder={t('nav_password')}
            />

            <Button
              variant="default"
              size="lg"
              className="w-full"
              type="submit"
              loading={mutation.isPending}
            >
              {t('submit')}
            </Button>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
