'use client'
import { disableQuery, useQuery } from '@connectrpc/connect-query'
import { getSiteMembership } from '@fingertip/creator-proto/gen/fingertip/creator/site_membership/v1/site_membership-SiteMembershipService_connectquery'
import { PencilIcon } from '@fingertip/icons'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { useToken } from '@/lib/hooks/use-token'

import { FloatingAction } from '../shared/floating-action'

export type Props = {
  siteSlug: string
  pageSlug: string
  isUnpublished?: boolean
}

export const UnpublishedButton = ({
  siteSlug,
  pageSlug,
  isUnpublished,
}: Props) => {
  const { t } = useTranslation()
  const { hasFetched, callOptions } = useToken()
  const searchParams = useSearchParams()
  const hideClaimButton = searchParams?.get('hide-claim-button')
  const opengraph = searchParams?.get('opengraph')

  const { data: siteMembership } = useQuery(
    getSiteMembership,
    !hasFetched && !isUnpublished
      ? disableQuery
      : {
          siteSlug,
        },
    {
      callOptions,
    },
  )

  if (!siteMembership?.membership) {
    return null
  }

  if (!isUnpublished) {
    return null
  }

  if (!!hideClaimButton || !!opengraph) {
    return null
  }

  return (
    <FloatingAction>
      <Button asChild className="w-full shadow-sm">
        <Link
          href={`/sites/${siteSlug}${pageSlug !== 'index' ? `/${pageSlug}` : ''}`}
        >
          <PencilIcon className="mr-2" />
          {t('continue_editing')}
        </Link>
      </Button>
    </FloatingAction>
  )
}
