'use client'

import { PartialMessage } from '@bufbuild/protobuf'
import { Media } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/media_pb'

import { getInitials } from '@/lib/utils/string-utils'

import { getMediaImageSrc } from '../blocks/shared/media.utils'
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar'

const IMAGE_SIZE = 80

type Props = {
  logoMedia?: PartialMessage<Media>
  name?: string | null
}

export const ClaimLogo = ({ logoMedia, name }: Props) => {
  const src = getMediaImageSrc({
    media: logoMedia,
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  })
  return (
    <Avatar className="size-[38px] rounded-[13px] shadow-xs">
      {src && <AvatarImage src={src} alt={name || ''} />}

      <AvatarFallback
        className="bg-page-card-background flex size-full items-center justify-center rounded-[13px] text-page-card-text"
        delayMs={src ? 300 : 0}
        value={getInitials(name || '')}
      />
    </Avatar>
  )
}
