'use client'
import { disableQuery, useMutation, useQuery } from '@connectrpc/connect-query'
import { UserRole } from '@fingertip/creator-proto/gen/fingertip/common/enum/v1/user_role_pb'
import { updateQrCodeShortId } from '@fingertip/creator-proto/gen/fingertip/creator/qr_code/v1/qr_code-QrCodeService_connectquery'
import { getUserProfile } from '@fingertip/creator-proto/gen/fingertip/creator/user_profile/v1/user_profile-UserProfileService_connectquery'
import { useSearchParams } from 'next/navigation'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import { useToken } from '@/lib/hooks/use-token'

export const UnlinkQrButton = () => {
  const { t } = useTranslation()

  const searchParams = useSearchParams()
  const qrShortId = searchParams?.get('id')

  const { token, callOptions } = useToken()

  const { data: userData } = useQuery(
    getUserProfile,
    !token ? disableQuery : {},
    {
      callOptions,
    },
  )

  const role = userData?.userProfile?.role

  const mutationUpdate = useMutation(updateQrCodeShortId, {
    callOptions,
    onSuccess: () => {
      toast(t('successfully_unlinke'))
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const handleClick = useCallback(() => {
    if (!qrShortId) {
      return
    }

    mutationUpdate.mutate({
      qrShortId,
      pageSlug: undefined,
      siteSlug: undefined,
    })
  }, [mutationUpdate, qrShortId])

  if (!qrShortId) {
    return null
  }

  if (role !== UserRole.ADMIN) {
    return null
  }

  return (
    <div className="mb-4 flex items-center justify-center">
      <Button onClick={handleClick}>{t('unlink_from_qr_code')}</Button>
    </div>
  )
}
