'use client'
import { PartialMessage } from '@bufbuild/protobuf'
import { GetPagePublicResponse } from '@fingertip/creator-proto/gen/fingertip/creator/page/v1/page_pb'
import { observer } from 'mobx-react-lite'
import { usePathname, useSearchParams } from 'next/navigation'
import { usePostHog } from 'posthog-js/react'
import { useCallback, useContext, useEffect } from 'react'
import { useWindowSize } from 'usehooks-ts'

import { ConditionalWrapper } from '@/components/shared/conditional-wrapper'
import { useEvents } from '@/lib/hooks/use-events'
import { PHEventName } from '@/lib/posthog/events'
import { RootStoreContext } from '@/lib/stores/root-store'

import { BlockContext, BlockSwitch } from '../blocks/shared/block-switch'
import {
  BLOCK_COLUMNS,
  BLOCK_GAP,
  BLOCK_HEIGHT,
  DEFAULT_WIDTH,
  MAX_WIDTH,
} from '../blocks/shared/constants'
import { BlockKind, BlockType } from '../blocks/shared/types'

type Props = {
  blocks?: PartialMessage<GetPagePublicResponse>['blocks']
  pageSlug?: string
  pageId?: string
  siteSlug: string
  siteId?: string
  domain?: string
  context: BlockContext
}

export const PublicPageBlocks = observer(
  ({ blocks, pageSlug, pageId, siteSlug, siteId, domain, context }: Props) => {
    const {
      pageEditorStore: { setScaleFactor, scaleFactor },
    } = useContext(RootStoreContext)

    const postHog = usePostHog()
    const events = useEvents()
    const pathname = usePathname()
    const searchParams = useSearchParams()
    const { width = DEFAULT_WIDTH } = useWindowSize()
    const viewportWidth = Math.min(width, MAX_WIDTH)

    useEffect(() => {
      setScaleFactor(Math.min(width, MAX_WIDTH) / DEFAULT_WIDTH)
    }, [width, setScaleFactor])

    const handleClick = useCallback(
      (block: BlockType, name?: string, websiteUrl?: string) => {
        if (context !== 'PUBLIC') {
          return
        }

        let url = window.origin + pathname
        if (searchParams && searchParams.toString()) {
          url = url + `?${searchParams.toString()}`
        }

        postHog.capture(PHEventName.PAGE_LINK_CLICK, {
          block_name: name || block.name,
          block_id: block.id,
          block_type: block.kind,
          page_slug: pageSlug,
          page_id: pageId,
          site_slug: siteSlug,
          site_id: siteId,
          block_website_url: websiteUrl,
        })

        events.capture(PHEventName.PAGE_LINK_CLICK, {
          url,
          pathname: pathname || undefined,
          blockName: name || block.name,
          blockId: block.id,
          blockType: block.kind,
          blockWebsiteUrl: websiteUrl,
          pageSlug,
          pageId,
          siteSlug,
          siteId,
        })
      },
      [
        context,
        events,
        pageId,
        pageSlug,
        pathname,
        postHog,
        searchParams,
        siteId,
        siteSlug,
      ],
    )

    return (
      <div
        className="relative grid"
        style={{
          gridTemplateColumns: `repeat(${BLOCK_COLUMNS}, minmax(0, 1fr))`,
          gap: BLOCK_GAP * scaleFactor,
          gridAutoRows: BLOCK_HEIGHT * scaleFactor,
        }}
      >
        {(blocks || []).map((block) => {
          const content = block?.content?.content?.value as any
          const w = content?.sm?.w !== undefined ? content?.sm.w : 1
          const h = content?.sm?.h !== undefined ? content?.sm.h : 1
          const x = content?.sm?.x !== undefined ? content?.sm.x + 1 : 1
          const y = content?.sm?.y !== undefined ? content?.sm.y + 1 : 1

          const hideBackground =
            content?.hideBackground || block.kind === 'PAGE_BREAK'

          return (
            <div
              key={block?.id}
              id={block?.id}
              style={{
                gridColumn: `${x} / span ${w}`,
                gridRow: `${y} / span ${h}`,
              }}
              className="relative"
            >
              <ConditionalWrapper
                condition={!hideBackground}
                wrapper={(children) => (
                  <div className="ft-widget-wrapper size-full rounded-page-widget text-neutral-900">
                    <div className="bg-page-widget-background shadow-page-widget border-page-widget size-full rounded-page-widget text-page-body-text backdrop-blur-page-widget">
                      {children}
                    </div>
                  </div>
                )}
              >
                <div className="relative z-10 size-full">
                  <div className="size-full text-fluid-base leading-tight">
                    <BlockSwitch
                      kind={block.kind as BlockKind}
                      block={block}
                      blockMeta={{ id: block.id, meta: block?.meta }}
                      w={w}
                      h={h}
                      y={y}
                      x={x}
                      handleClick={handleClick}
                      siteSlug={siteSlug}
                      domain={domain}
                      context={context}
                      viewportWidth={viewportWidth}
                      scaleFactor={scaleFactor}
                    />
                  </div>
                </div>
              </ConditionalWrapper>
            </div>
          )
        })}
      </div>
    )
  },
)
